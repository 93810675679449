<template>
    <MetaInfo :title="meta.title" :description="meta.description"></MetaInfo>
  
    <navigation></navigation>
  
    <PageHeader title="Text Messaging Terms"></PageHeader>
  
    <div class="container-fluid">
      <div class="row mb-30">
        <div class="col-xs-12 col-md-3 mb-20">
          <TermsNav></TermsNav>
        </div>
        <div class="col-xs-12 col-md-6 col-md-offset-1">
          <div class="longform">
            <p>
                During the closed Beta for Text Messaging, Customers will receive 100 free credits to get started. Customers will then be able to purchase additional bundles of credits on a Pay as You Go basis as explained in the app. Unused credits will be carried over following a full launch of Text Messaging even if there is a change to the commercial model.
            </p>
            <p>
                You can deactivate your Text Messaging via the Early Access Features section in your Transpond account. Cancelling your Transpond account will disable Text Messaging immediately.
            </p>
            <p>
                We may limit the number of messages or the time of day you can send messages as part of Text Messaging. By using Text Messaging, you agree to:
            </p>
            <ul>
                <li>
                    Not send SMS messages to anyone you do not have the required consent to contact or without the required opt-out method or language within the SMS message;
                </li>
                <li>
                    Not send any content that is in violation of our <a title="Acceptable Use Policy" href="/acceptableuse">Acceptable Use Policy</a>;
                </li>
                <li>
                    Comply with all laws, rules, regulations, best practices, policies, or carrier requirements applicable to Text Messaging (including, but not limited to, TPS/ CTPS restrictions in the UK, SHAFT restrictions, quiet hours, advertising restrictions, and direct marketing rules).
                </li>
                <li>
                    Within the time period prescribed under applicable laws, rules, or regulations action all opt-out, unsubscribes, or other requests from Contacts who do not want to be contacted via Text Messaging.
                </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  
    <site-footer></site-footer>
  </template>
  
  <script>
  import MetaInfo from "@/components/MetaInfo";
  import Navigation from "@/components/navigation/Main";
  import SiteFooter from "@/components/SiteFooter";
  import TermsNav from "@/components/Terms/TermsNav";
  import PageHeader from "@/components/Header/PageHeader";
  
  export default {
    name: "TextMessagingTerms",
    components: {
      MetaInfo,
      Navigation,
      SiteFooter,
      TermsNav,
      PageHeader,
    },
  
    data() {
      return {
        meta: {
          title: "Text Messaging Terms - Transpond",
          description:
            "Take a look at the terms and conditions for using SMS on the Transpond Platform",
        },
      };
    },
  };
  </script>
  